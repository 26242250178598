import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import HomeLoader from './pages/HomeLoader';
import HomeLoaderImage from './pages/HomeLoaderImage';
import NotFoundPage from './pages/NotFoundPage';
import ReactGa from 'react-ga';
import { useEffect } from 'react';
import {Helmet} from 'react-helmet';
function App() {
  useEffect(()=>{
    ReactGa.initialize(process.env.REACT_APP_GA_TRACKING_CODE)
    ReactGa.pageview(window.location.pathname + window.location.search)
  },[])
  return (
    <>
      <Router>
        <Helmet>
          <title>Nicolas Golob | Web Developer</title>
          <meta name="description" content="Nicolas Golob's Portfolio"/>
          <meta name="keywords" content="web developer, nicolas golob, portfolio, homeloader"/>
        </Helmet>
      <Routes>
        <Route path="/" element={<HomeLoader />}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/homeloaderimage" element={<HomeLoaderImage/>}/>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
     </Router> 
    </>
  );
}
export default App;
