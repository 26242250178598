import React from 'react';
import NavBarAbout from '../components/NavBarAbout';
import AboutMain from '../components/AboutMain';
// import FooterAbout from '../components/FooterAbout';
import {Helmet} from 'react-helmet';
export default function Home() {
    return (
        <>
            <Helmet>
                <title>Nicolas Golob | About</title>
                <meta name="description" content="About Nicolas Golob"/>
                <meta name="keywords" content="web developer, nicolas golob, portfolio, about"/>
            </Helmet>
            <NavBarAbout/>
                <AboutMain/>
                {/* <FooterAbout/> */}
        </>
    )
}
