import React from 'react';
import NavBar from '../components/NavBar';
import Main from '../components/Main';
import Footer from '../components/Footer';
import {Helmet} from 'react-helmet';

export default function Home() {
    return (
        <>
           <Helmet>
                <title>Nicolas Golob | Home</title>
                <meta name="description" content="Nicolas Golob's Projects"/>
                <meta name="keywords" content="web developer, nicolas golob, portfolio, home, projects"/>
            </Helmet>
            <NavBar/>
                <Main/>
            <Footer/>
        </>
    )
}
