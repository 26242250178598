import React, {  useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import {gsap} from 'gsap';

const NavBar = () => {
	const [openNav, setOpenNav] = useState(false);
	const handleClick = () => {
		setOpenNav(!openNav);
	};

	const closeMobileMenu = () => {
		setOpenNav(false);
	};

	let brandName = useRef(null);
	let navLink = useRef(null);

	useEffect(()=>{
		gsap.to(brandName.current,{
			autoAlpha:1,
			y:0,
			duration:.8,
			ease:"power3.easeIn"
		},[])
	});
    useEffect(()=>{
		gsap.to(navLink.current,{
			autoAlpha:1,
			y:0,
			duration:.7,
			ease:"power3.easeIn",
			delay:1.2
		},[])
	});
	return (
		<nav className="navbar" >
			<div className="navbar-name fade-in" ref={brandName}>
                NICOLAS GOLOB 
            </div> 
			<div onClick={handleClick} className="nav-icon">
                <i className={openNav ? "fas fa-times" : "fas fa-bars"}/>
			</div>
			<ul ref={navLink} className={openNav? 'nav-links active' : 'nav-links'}>
				<li className="nav-item" >
					<Link to="/about" className="nav-link fade-in" onClick={closeMobileMenu}>ABOUT ME</Link>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;