import React,{useState} from 'react'
export default function HomeLoaderImage() {
    const [image] = useState([{
            id:1,
            image : 'homeloader.jpg'
        }
    ]);
    return (
        <div>
            {image.map(i=>
            <img  key={i.id}
            src={require(`../assets/image/${i.image}`).default} alt={''}  style={{height:720}} />
            )}
        </div>
    )
}
