import React from 'react';
import "./Main.css";
import {Link} from 'react-router-dom';

export default function MainItem(props) {
    return (
        <>
        <div className="item">
         <img className="item--image" src={require(`../assets/image/${props.image}`).default}  alt={''}/>     
            <Link to={props.path} target="_blank" className="item--describe">
                <i className="fas fa-arrow-right"></i>
                {props.name}
            </Link>
        </div>
        </>
    )
}
