import React, { useState, useRef, useEffect } from 'react';
import Home from './Home';
import './HomeLoader.css';

const HomeLoader = () => {
    const [preloader, setPreloader] = useState(true);
    const [timer, setTimer] = useState(3);
    const id = useRef(null);
    const clear = () => {
        window.clearInterval(id.current);
        setPreloader(false);
    }
    useEffect(() => {
        id.current = window.setInterval(() => {
            setTimer(timer => timer - 1)
        }, 1300)
    }, []);
    useEffect(() => {
        if (timer === 0) {
            clear();
        }
    }, [timer]);
    return (
        <>
            {preloader ? (
                <div className="loader-wrapper loader-absolute fade-in-homeloader-title">
                    <h1 className="loader-h1">NICOLAS GOLOB</h1>
                    <h2 className="loader-h2">Web Developer</h2>
                </div>
            ) : (
                <div>
                    <Home />
                </div>
            )}
        </>
    );
};
export default HomeLoader;

