import React, { useEffect, useRef } from 'react';
import './Footer.css';
import ButtonMailto from './ButtonMailto';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

export default function Footer() {
    let footerAnime = useRef(null);
    useEffect(() => {
        gsap.to(footerAnime.current, {
            autoAlpha: 1,
            y: 0,
            duration: 1.5,
            ease: "power3.easeIn",
            delay: 3
        }, [])
    });
    return (
        <>
            <section className="main-footer" ref={footerAnime}>
                <div className="available-message">
                    <p>Web Developer</p>
                </div>
                <div className="social-media">
                    <ButtonMailto className="email-media" mailto="mailto:nicolasgolob@gmail.com" label="Email" />
                    {/* <Link to="//github.com/nicolasGolob/" target="_blank" className="github-media"><i className="fab fa-github"></i></Link>
                <Link to="//linkedin.com/in/nicolas-golob"target="_blank" className="linkedin-media"><i className="fab fa-linkedin"></i></Link> */}
                    {/* <Link to="//behance.net/nicolasgolob/" target="_blank" className="behance-media"><i className="fab fa-behance"></i></Link> */}
                </div>
            </section>
        </>
    )
}
