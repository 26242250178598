import React from "react";
import './ButtonAboutMailto.css';
import { Link } from "react-router-dom";

const ButtonAboutMailto = ({ mailto, label }) => {
    return (
        <Link to='#' className="email-about" onClick={(event) => {
                window.location = mailto;
                event.preventDefault();
            }}>
        {label}
        </Link>
    );
};
export default ButtonAboutMailto;