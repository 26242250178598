import React from "react";
import './ButtonMailto.css';
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link to='#' className="email-media" onClick={(event) => {
                window.location = mailto;
                event.preventDefault();
            }}>
        <i className="far fa-envelope"></i>
        </Link>
    );
};
export default ButtonMailto;