import React, { useRef, useEffect } from 'react';
import './AboutMain.css';
import ButtonAboutMailto from './ButtonAboutMailto';
import { Link } from 'react-router-dom';
import { gsap } from "gsap";

export default function AboutMain() {
    const tl = gsap.timeline();
    let aboutTitle1 = useRef(null);
    let aboutTitle2 = useRef(null);
    let aboutSubtitle = useRef(null);
    let aboutText = useRef(null);
    let aboutMedia = useRef(null);
    let aboutSkillsTitle = useRef(null);
    let aboutSkills = useRef(null);
    useEffect(() => {
        tl
            .to(aboutSkills.current, {
                autoAlpha: 1,
                y: 0,
                duration: 1.5,
                ease: "power3.easeIn",
            }, 6)
            .to(aboutSkillsTitle.current, {
                autoAlpha: 1,
                x: 0,
                duration: 1.5,
                ease: "power3.easeIn",
            }, 5)
            .to(aboutTitle1.current, {
                autoAlpha: 1,
                y: 0,
                duration: 1.4,
                ease: "power3.easeIn",
            }, 4)
            .to(aboutTitle2.current, {
                autoAlpha: 1,
                y: 0,
                duration: 1.4,
                ease: "power3.easeIn",
            }, 3)
            .to(aboutSubtitle.current, {
                autoAlpha: 1,
                y: 0,
                duration: 1.7,
                ease: "power3.easeIn",
            }, 2)
            .to(aboutText.current, {
                autoAlpha: 1,
                y: 0,
                duration: 1.5,
                ease: "power3.easeIn",
            }, 1)
            .to(aboutMedia.current, {
                autoAlpha: 1,
                y: 0,
                duration: 1,
                ease: "power3.easeIn",
            }, 0)
    });
    return (
        <>
            <section className="about-section">
                <div className="about-container">
                    <div className="about-title1" ref={aboutTitle1}>About</div>
                    <div className="about-title2" ref={aboutTitle2}>Nicolas</div>
                    <div className="about-subtitle" ref={aboutSubtitle}>Who's this guy?</div>
                    <div className="about-text" ref={aboutText}>I am 33 years old and I live near the french city of Metz.<br />
                        The projects realized teach me new ways of doing things, new paradigms but above all to go further.<br /><br />
                        Also I have a passion for digital art and have been able to use my skills to generate illustrations and create logos during some projects.<br /><br />
                        I continue to practice, learn new technologies, paradigms, create new designs and most of all enjoy coding and the digital creation.
                        <div className="about-media" ref={aboutMedia}>
                            <i className="fas fa-arrow-circle-right media-about"></i><ButtonAboutMailto className="email-about" mailto="mailto:nicolasgolob@gmail.com" label="Email"><i className="fas fa-envelope"></i></ButtonAboutMailto>
                            {/* <i className="fas fa-arrow-circle-right media-about"></i><Link to="//github.com/nicolasGolob/" target="_blank" className="github-about">Github</Link>
                            <i className="fas fa-arrow-circle-right media-about"></i><Link to="//linkedin.com/in/nicolas-golob" target="_blank" className="linkedin-about">LinkedIn</Link>
                            <i className="fas fa-arrow-circle-right media-about"></i><Link to="//behance.net/nicolasgolob/" target="_blank" className="behance-about">Bē</Link> */}
                        </div>
                    </div>
                    <div className="about-master-container">
                        <p className="about-skills-title" ref={aboutSkillsTitle}>Technologies I Use The Most, I Adapt Very Quicly To New Ones </p>
                        <div className="about-skills-container" ref={aboutSkills}>
                            <div className="about-skills-row">
                                <ul className="about-skills-column">
                                    <li>React Js</li>
                                    <li>Javascript</li>
                                    <li>Redux</li>
                                    <li>Node Js</li>
                                    <li>Firebase</li>
                                    <li>Express Js</li>
                                    <li>Wordpress</li>
                                </ul>
                            </div>
                            <div className="about-skills-row">
                                <ul className="about-skills-column">
                                    <li>A. Illustrator</li>
                                    <li>A. PhotoShop</li>
                                    <li>A. InDesign</li>
                                    <li>CSS3 / SASS</li>
                                    <li>GSAP</li>
                                    <li>BootStrap</li>
                                    <li>Materialize</li>
                                    <li>Figma</li>
                                </ul>
                            </div>
                            <div className="about-skills-row">
                                <ul className="about-skills-column">
                                    <li>Git</li>
                                    <li>GitHub</li>
                                    <li>Html5</li>
                                    <li>PHP</li>
                                    <li>mySQL</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
