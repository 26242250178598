import React from 'react';
import NavBarError from '../components/NavBarError';
import ErrorMain from '../components/ErrorMain';

export default function NotFoundPage() {
    return (
        <>
            <NavBarError/>
            <ErrorMain/> 
        </>
    )
}
