import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBarError.css';
const NavBarError = () => {
	const [openNav, setOpenNav] = useState(false);
	const handleClick = () => {
		setOpenNav(!openNav);
	};
	const closeMobileMenu = () => {
		setOpenNav(false);
	};
	return (
		<nav className="navbar-error">
			<div onClick={handleClick} className="nav-icon">
                <i className={openNav ? "fas fa-times" : "fas fa-bars"}/>
			</div>
			<ul className={openNav? 'nav-error-links active' : 'nav-error-links'}>
				<li className="nav-error-item">
					<Link to="/home" className="nav-error-link" onClick={closeMobileMenu}>HOME</Link>
				</li>
			</ul>
		</nav>
	);
};
export default NavBarError;