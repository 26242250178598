import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBarAbout.css';
const NavBarAbout = () => {
	const [openNav, setOpenNav] = useState(false);
	const handleClick = () => {
		setOpenNav(!openNav);
	};
	const closeMobileMenu = () => {
		setOpenNav(false);
	};
	return (
		<nav className="navbar-about">
			<div onClick={handleClick} className="nav-icon">
                <i className={openNav ? "fas fa-times" : "fas fa-bars"}/>
			</div>
			<ul className={openNav? 'nav-about-links active' : 'nav-about-links'}>
				<li className="nav-about-item">
					<Link to="/home" className="nav-about-link" onClick={closeMobileMenu}>HOME</Link>
				</li>
			</ul>
		</nav>
	);
};

export default NavBarAbout;