import React from 'react';
import './ErrorMain.css';

const ErrorMain = () =>{
    return (
        <>
        <section className="error-wrapper error-absolute">
            <h1 className="error-h1">Error 404</h1>
            <h2 className="error-h2">The Requested Page Does Not Exist</h2>
        </section>
        </>
    );
};
export default ErrorMain;

