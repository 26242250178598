import React, {  useState, useEffect, useRef} from 'react';
import './Main.css';
import MainItem from './MainItem';
import {gsap} from 'gsap';

export default function Main() {
    let titleMain = useRef(null);
	let accordeonProjects = useRef(null);
    useEffect(()=>
        console.log('Hi There! Welcome to the portfolio of Nicolas Golob')
        ,[]
    )
    useEffect(()=>{
		gsap.to(titleMain.current,{
			autoAlpha:1,
			y:0,
			duration:1.5,
			ease:"power3.easeIn",
            delay:2.5
		},[])
	});
    useEffect(()=>{
		gsap.to(accordeonProjects.current,{
			autoAlpha:1,
			y:0,
			duration:1.2,
			ease:"power3.easeIn",
            delay:1.6,
		},[])
	});
    const [projects] = useState([
        // {  
        //     id:1,
        //     name: "Explore",
        //     path:"//summit-website-nicolasgolob.netlify.app/",
        //     image : 'summit.png'
        // },
        {
            id:2,
            name: "Explore",
            path:"//crypto-currency-nicolasgolob.netlify.app//",
            image : 'crypto-currency.png'
        },
        {
            id:3,
            name: "Explore",
            path:"//pictures-website-nicolasgolob.netlify.app",
            image : 'pictures.png'
        },
        {
            id:4,
            name: "Explore",
            path:"//www.nicolasgolob.com",
            image : 'nicolas.png'
        },
        {
            id:5,
            name: "Explore",
            path:"#",
            image : 'symbole.png'
        }  
      ]);
    return (
        <>
           <section className="accordion-section"> 
            <h1 className="accordion-title" ref={titleMain}>Some Projects</h1>
                <div className="accordion-wrap" ref={accordeonProjects}>{
                        projects.map(item=> 
                            <MainItem   
                                key={item.id}
                                image={item.image}
                                name={item.name}
                                path={item.path}
                            />                       
                        )
                    }
                </div>                
        </section>  
        </>
    )
}
